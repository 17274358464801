/* card view style */
.popup {
  justify-content: center;
  display: flex !important;
  position: fixed !important;
}

.popup .modal-body {
  padding: 0em !important;
}

.popup .modal-content {
  display: flex;
  flex-direction: column;
  min-height: 347px;
  position: relative;
  width: 480px !important;
  background: #ffffff;
  border-radius: 15px !important;
  border: 2px solid #e5e5e5 !important;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: calc(100vh - 50vh - 48vh);
}

.close-btn {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  background: #f8f8f8;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  color: #848484;
  border: 1px solid #f0efef;
}

.close-btn:focus {
  outline: none;
}

.activate-btn {
  pointer-events: auto;
  cursor: pointer;
  background: #ed1c24;
  width: 420px;
  height: 45px;
  text-align: center;
  border: none;
  font-size: 16px;
  color: #fff;
  font-style: normal;
  font-weight: bold;
  position: absolute;
  bottom: 40px;
  left: 30px;
  box-shadow: 0px 6px 15px rgba(50, 50, 50, 0.156277);
  border-radius: 5px;
}

.activate-btn:focus {
  outline: none;
}

.c-title {
  position: absolute;
  height: 51px;
  left: 31.29%;
  right: 33.54%;
  top: 40px;
  font-family: "tondoStd";
  font-size: 35px;
  line-height: 47px;
  font-weight: bold;
  text-align: center;
  color: #000000;
}

.p-title {
  position: absolute;
  height: 23px;
  left: 34.17%;
  right: 35.48%;
  top: 96px;
  font-family: "tondoStd";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #000000;
}

.data-list {
  margin-bottom: 180px;
}

.d-title {
  position: relative;
  top: 62px;
  left: 30px;
  text-align: left;
  font-family: "tondoStd";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #505154;
}

.details {
  border-top: 1px solid #d8d8d8;
  position: relative;
  top: 62px;
  left: 30px;
  width: 415px;
  text-align: left;
  font-family: "tondoStd";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #505154;
}

.list-group-card {
  line-height: 18px;
  list-style: none;
  font-family: "tondoStd";
  font-size: 16px;
  margin-top: 100px;
}

.list-icon-card {
  display: inline;
  left: 32px;
  position: absolute;
}

.list-lbl-card {
  position: absolute;
  left: 66px;
  font-style: normal;
  font-weight: normal;
}

.list-txt-card {
  position: absolute;
  right: 30px;
  font-style: normal;
  font-weight: normal;
}

.validity-icon {
  line-height: 14px;
  left: 32px;
  position: absolute;
}

.validity-label {
  line-height: 18px;
  position: absolute;
  left: 66px;
  font-style: normal;
  font-weight: normal;
  font-family: "tondoStd";
  font-size: 16px;
}

.validity-txt {
  line-height: 18px;
  position: absolute;
  right: 30px;
  font-style: normal;
  font-weight: normal;
  font-family: "tondoStd";
  font-size: 16px;
}

@media (max-width: 575.98px) {
  .popup {
    justify-content: center;
    display: flex !important;
    position: fixed !important;
  }

  .popup .modal-body {
    padding: 0em !important;
  }

  .popup .modal-content {
    display: flex;
    flex-direction: column;
    min-height: 307px;
    position: relative;
    width: 80vw !important;
    margin-top: calc(100vh - 50vh - 45vh);
    background: #ffffff;
    border-radius: 15px !important;
    border: 2px solid #e5e5e5 !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .close-btn {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    background: #f8f8f8;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    color: #848484;
    border: 1px solid #f0efef;
  }

  .close-btn:focus {
    outline: none;
  }

  .activate-btn {
    pointer-events: auto;
    cursor: pointer;
    background: #ed1c24;
    width: 68vw;
    height: 45px;
    text-align: center;
    border: none;
    font-size: 16px;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    position: absolute;
    bottom: 40px;
    left: 5.5vw;
    box-shadow: 0px 6px 15px rgba(50, 50, 50, 0.156277);
    border-radius: 5px;
  }

  .activate-btn:focus {
    outline: none;
  }

  .c-title {
    position: absolute;
    height: 51px;
    display: flex;
    justify-content: center;
    top: 36px;
    font-family: "tondoStd";
    font-size: 34px;
    line-height: 47px;
    font-weight: bold;
    text-align: center;
    color: #000000;
  }

  .p-title {
    position: absolute;
    height: 23px;
    display: flex;
    justify-content: center;
    top: 82px;
    font-family: "tondoStd";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #000000;
  }

  .data-list {
    margin-bottom: 160px;
  }

  .d-title {
    position: relative;
    top: 52px;
    left: 5.5vw;
    text-align: left;
    font-family: "tondoStd";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #505154;
  }

  .details {
    border-top: 1px solid #d8d8d8;
    position: relative;
    top: 52px;
    left: 5.5vw;
    width: 68vw;
    text-align: left;
    font-family: "tondoStd";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #505154;
    text-align: left;
  }

  .list-group-card {
    line-height: 16px;
    list-style: none;
    font-family: "tondoStd";
    font-size: 15px;
    margin-top: 76px;
  }

  .list-icon-card {
    display: inline;
    left: 5.5vw;
    position: absolute;
  }

  .list-lbl-card {
    position: absolute;
    left: 12vw;
    font-style: normal;
    font-weight: normal;
  }

  .list-txt-card {
    position: absolute;
    right: 5.5vw;
    font-style: normal;
    font-weight: normal;
  }

  .validity-icon {
    line-height: 5px;
    left: 5.5vw;
    position: absolute;
  }

  .validity-label {
    line-height: 16px;
    position: absolute;
    left: 12vw;
    font-style: normal;
    font-weight: normal;
    font-family: "tondoStd";
    font-size: 15px;
  }

  .validity-txt {
    line-height: 16px;
    position: absolute;
    right: 5.5vw;
    font-style: normal;
    font-weight: normal;
    font-family: "tondoStd";
    font-size: 15px;
  }
}
