@font-face {
  font-family: tondoStd;
  src: url(./fonts/Tondo_Std.ttf);
}

@font-face {
  font-family: tondoLt;
  src: url(./fonts/Tondo_Std_Lt.ttf);
}

@font-face {
  font-family: tondoSig;
  src: url(./fonts/Tondo_Std_Sign.ttf);
}

@font-face {
  font-family: tondoReg;
  src: url(./fonts/Tondo_Std_Rg.ttf);
}

.sun {
  background-color: red;
  -webkit-mask: url(./images/icons/sun.svg) no-repeat center;
  mask: url(./images/icons/sun.svg) no-repeat center;
}

body {
  height: 100%;
  overflow: hidden;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  body {
    overflow: scroll;
  }
}

/* Extra large devices (large desktops) */

#left_panel {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  padding: 0px;
  /* background-image: url(./images/banners/bg_road.jpg); */
  background-repeat: repeat;
}

.left-img-banner {
  width: 100%;
  object-fit: cover;
  float: right;
}

.alice-carousel-mobile .alice-carousel__dots {
  margin: 0% !important;
  height: 0px !important;
}

.sliderimg {
  width: 100%;
  object-fit: cover;
  float: right;
}

.sliderimg-mobile {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

#right_panel {
  overflow-y: scroll !important;
  padding-top: 20px;
  padding: 0px 15px 0px 15px;
  height: 100vh;
  background-color: #000000;

  /* background: #515251;
  background: -moz-linear-gradient(
    left,
    #515251 0%,
    #0e0e0e 100%
  );
  background: -webkit-linear-gradient(
    left,
    #515251 0%,
    #0e0e0e 100%
  );
  background: linear-gradient(
    to right,
    #515251 0%,
    #0e0e0e 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7d7e7d', endColorstr='#0e0e0e',GradientType=1 ); */

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

#right_panel .header {
  background-color: #ffffff;
  padding-top: 25px;
}

#right_panel .content {
  padding: 0px 15px;
  min-height: 65%;
  /*margin-bottom: 20px;*/
  background-color: #ffffff;
  margin-top: -2px;
}

.right_footer {
  margin-top: 0px;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* common */

.airtel-red {
  color: #ed1c24;
}

.img_logo {
  width: 100px;
}

#right_panel h1 {
  margin-top: 20px;
  font-size: 40px;
  font-family: tondoStd;
}

/* form */
.form-panel {
  padding-top: 18px;
}

.form-panel label {
  font-size: 20px;
  font-family: "tondoReg";
  color: #1a1a1a;
  opacity: 0.6 !important;
  text-transform: uppercase;
}

.customPrefixInput {
  display: block;
}

.customPrefixInput .customPrefix {
  display: inline;
  font-size: 28px;
  padding-bottom: 3px;
  font-family: "tondoLt";
}
.customPrefixInput .omo-input {
  display: inline;
  margin-left: -2px;
}

.omo-input {
  border: none;
  /* border-bottom: 1px solid #999; */
  display: block;
  font-size: 28px;
  background-color: transparent;
  font-family: "tondoLt";
}

.omo-input:focus {
  outline: none;
}

.omo-input-vl {
  font-size: 20px;
}

#right_panel h2 {
  margin-top: 30px;
  font-size: 35px;
  font-family: "tondoReg";
  color: #1a1a1a;
}

/* CARD */

.card {
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border: 2px solid #e0e0e0 !important;
}

.card .card-title {
  font-family: "tondoSig";
  top: 0%;
}

.card:hover {
  border-color: #b4b4b4 !important;
}

.card.selected {
  border: 2px solid #ed1c24 !important;
}

.card-body {
  padding: 1rem !important;
  background-color: transparent;
}

.card .card-text {
  font-size: 14px;
  font-family: "tondoLt";
}

.card .list-group {
  list-style: none;
}
.card .list-group li {
  padding-top: 2px;
}
.card .list-group li i {
  margin-right: 10px;
}

.card .card-term {
  font-size: 12px;
  margin-right: 12px;
  bottom: 0;
  right: 0;
  position: absolute;
  font-weight: 900;
}

.card .selected-icon {
  float: right;
  font-size: 25px;
  color: #ed1c24;
}

/* Summary */

.summary-container {
  margin-top: 10px;
}

.summary-back-button {
  background: none;
  border: none;
  margin-bottom: 20px;
  padding-left: 0 !important;
}

.summary-back-button:hover {
  color: #000000 !important;
}

.summary-back-button:focus {
  outline: none;
}

.summary-back-button span {
  color: #ed1e25;
  padding-top: 2px;
  position: absolute;
  left: 50px;
}

.summary-back-button:hover span {
  font-size: 22px;
  padding-top: 0px;
}

.summary-container .sum-title {
  display: block;
  font-size: 16px;
  font-family: "tondoReg";
  color: #1a1a1a;
}

.summary-container .sum-value {
  font-size: 18px;
  font-weight: lighter;
  color: #272626;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.summary-container .sum-value .sum-data:not(:last-child) {
  border-right: 1px solid #a1a0a0;
  padding-right: 10px;
}

.summary-container .sum-value .sum-data:not(:first-child) {
  padding-left: 10px;
}

.modal-content h5 {
  font-size: 35px;
}

.recommend-button {
  padding: 10px 10px;
  margin-left: 16px;
  margin-top: 10px;
  background-color: #27ae60;
  color: #ffffff;
  border: 1px solid #27ae60;
  border-radius: 5px;
  font-family: "tondoStd";
  font-style: normal;
  font-weight: 400;
  width: 160px;
  height: 45px;
  font-size: 18px;
  line-height: 23px;
}

.recommend-button:focus {
  outline: none;
}

.package-category {
  font-family: "tondoReg";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}

.airtel-button {
  padding: 10px 10px;
  background-color: #ed1c24;
  color: #ffffff;
  border: 1px solid #ed1c24;
  border-radius: 5px;

  min-width: 240px;
}

.airtel-button:focus {
  outline: none;
}

.airtel-button:hover {
  background-color: #db0b12;
}

.modal-button {
  margin-right: 10px;
  min-width: 0px;
  width: 100%;
}

hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.card .list-txt {
  padding-left: 8px;
  display: inline;
  position: absolute;
  padding-top: 1px;
}

.card .card-text {
  font-size: 14px;
  font-family: "tondoStd";
}

.card .card-btn {
  position: absolute;
  right: 6%;
  bottom: 6%;
}

#btn_home_next:disabled {
  opacity: 0.6;
}
#btn_home_next:disabled:hover {
  opacity: 0.6;
}

.bottom-border-wrapper {
  border-bottom: 1px solid #e5e6e7;
  padding-bottom: 9px;
}

.focus-border {
  width: 0%;
  max-width: 370px;
  height: 1px;
  transition: 1s;
  background-color: #ed1e25;
  z-index: 10000;
}

/* .omo-input:focus ~ .focus-border{width: 100%; transition: 1s;} */

.mt-20 {
  margin-top: 20px;
}

.modal-footer {
  border-top: 0px !important;
}

#right_content_container {
  width: 100%;
}

.right_footer .copyright {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.right_footer h6 {
  font-size: 13px;
}

.right_footer_end {
  position: "absolute";
  width: 100%;
  bottom: 0;
}

.footer-sec-1 {
  text-align: left;
}

.footer-sec-2 {
  text-align: left;
  padding-bottom: 20px;
}

.hideme {
  display: none !important;
}

.input_error_msg {
  font-size: 16px;
  padding-top: 5px;
  color: #ed1e25;
}

.btn-circle-social {
  width: 32px;
  height: 32px;
  text-align: center !important;
  padding: 3px 0 !important;
  font-size: 18px !important;
  line-height: 1.428571429 !important;
  border-radius: 25px !important;
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
}

.btn-circle-social:hover {
  color: #272626 !important;
  background-color: #e6e7e8;
}

@media (min-height: 761px) and (max-height: 800px) {
  #right_panel .content {
    min-height: 70%;
  }
}

@media (min-height: 801px) and (max-height: 900px) {
  #right_panel .content {
    min-height: 72%;
  }
}

@media (min-height: 901px) and (max-height: 989px) {
  #right_panel .content {
    min-height: 74%;
  }
}

@media (min-height: 990px) and (max-height: 1100px) {
  #right_panel .content {
    min-height: 78%;
  }
}

@media (min-height: 1101px) and (max-height: 1200px) {
  #right_panel .content {
    min-height: 80%;
  }
}

@media (min-height: 1201px) and (max-height: 1300px) {
  #right_panel .content {
    min-height: 80%;
  }
}

@media (min-height: 1301px) and (max-height: 1340px) {
  #right_panel .content {
    min-height: 82%;
  }
}

@media (min-height: 1341px) and (max-height: 1400px) {
  #right_panel .content {
    min-height: 83%;
  }
}

/* @media(min-width:1200px) and (max-height:1400px){
  #right_panel .content {
    min-height: 82% !important;
  }
} */

@media (min-height: 1401px) and (max-height: 1500px) {
  #right_panel .content {
    min-height: 83%;
  }
}

@media (min-height: 1501px) and (max-height: 1600px) {
  #right_panel .content {
    min-height: 84%;
  }
}

@media (min-height: 1601px) and (max-height: 1700px) {
  #right_panel .content {
    min-height: 85%;
  }
}

@media (min-height: 1701px) and (max-height: 1800px) {
  #right_panel .content {
    min-height: 86%;
  }
}

@media (min-height: 1801px) and (max-height: 1900px) {
  #right_panel .content {
    min-height: 87%;
  }
}

@media (min-height: 1901px) and (max-height: 2000px) {
  #right_panel .content {
    min-height: 88%;
  }
}

@media (min-height: 2001px) {
  #right_panel .content {
    min-height: 90%;
  }
}

@media (max-width: 575.98px) {
  #right_panel h1 {
    font-size: 30px;
  }

  #right_panel h2 {
    font-size: 20px;
  }

  .package-category {
    font-size: 20px;
  }

  .form-panel label {
    font-size: 15px;
  }

  .customPrefixInput .customPrefix {
    font-size: 20px;
  }

  .omo-input {
    font-size: 20px;
    max-width: 160px;
    width: 160px;
  }

  .omo-input-vl {
    font-size: 20px;
  }

  .bottom-border-wrapper {
    padding-bottom: 6px;
  }

  .airtel-button {
    width: 100%;
  }

  /* card */
  .card .card-title {
    position: absolute;
    float: right;
    bottom: 0;
    right: 0;
    margin-right: 10px;
    margin-bottom: -3px !important;
    font-size: 40px;
  }
  .card .card-title span {
    font-size: 20px;
  }

  .card.selected .card-title {
    color: #ed1c24 !important;
  }

  .card .card-body {
    margin-bottom: 20px;
  }
  .card .card-term {
    margin-right: 170px;
    bottom: 0;
    right: 0;
    position: absolute;
    color: #ffffff;
    font-size: 12px;
    text-align: right;
    margin-bottom: 5px;
  }
  .card .card-text {
    color: #ffffff;
    font-size: 14px;
  }

  .card .card-btn {
    position: absolute;
    right: 3%;
    bottom: 8%;
  }

  .card .fe_icon {
    fill: #ffffff;
  }

  .card {
    background-image: url(./images/rec.png);
    background-repeat: no-repeat;
    background-position: right;
    background-color: #ed1c24 !important;
    border: 1px solid #b6b3b3 !important;
    height: 140px;
  }

  .modal-content h5 {
    font-size: 25px;
  }

  .modal-button {
    min-width: 0px;
    font-size: 12px;
  }

  .sticky {
    z-index: 1000 !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    bottom: 0 !important;
    background-color: #ffffff !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .airtel-button {
    width: 100%;
  }

  .focus-border {
    max-width: 240px;
  }
}

@media (min-width: 576px) {
  .card {
    width: 10rem;
    display: inline-block !important;
    height: 175px;
    border-radius: 10px !important;
  }
  .card .card-title {
    margin-bottom: 0px !important;
    font-size: 2rem;
  }

  .card .card-term {
    margin-bottom: 0.3rem;
  }
  .card .card-body {
    padding: 10px 10px !important;
  }

  .card .card-text {
    color: #222222;
  }

  .h5,
  h5 {
    font-size: 1.7rem;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  #right_panel h1 {
    font-size: 40px;
  }

  #right_panel h2 {
    font-size: 25px;
  }

  .package-category {
    font-size: 22px;
  }

  .form-panel label {
    font-size: 20px;
  }

  .customPrefixInput .customPrefix {
    font-size: 28px;
  }

  .omo-input {
    font-size: 28px;
  }
}

@media (min-width: 768px) {
  .footer-sec-2 {
    text-align: right !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  #right_panel h1 {
    font-size: 40px;
  }
}

/* @media (min-width: 768px) {
 
}

@media (max-width: 767.98px) {
  

  .airtel-button {
    width: 100%;
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background-color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .footer-sec-2 {
    text-align: left !important;
    padding-bottom: 20px;
  }
} */

.loading-container {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 100000;
  margin-left: -20px;
  position: absolute;
}

.loading-ripple-holder {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
}
.lds-ripple div {
  position: absolute;
  border: 6px solid #ed1c24;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 75px;
    left: 75px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 150px;
    height: 150px;
    opacity: 0;
  }
}

.payment-option-container {
  display: inline-block !important;
  width: 100px;
  height: 100px;
}

.payment-option:hover {
  border-color: #b4b4b4 !important;
}

.payment-option.selected {
  border: 2px solid #ed1c24 !important;
}
