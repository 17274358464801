/*Desktop View*/
.header-nav {
  display: flex;
  justify-content: space-between;
}
.nav-options {
  padding-left: 25px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 20px;
  list-style-type: none;
}
.mobile-option {
  display: none;
}
.option > a {
  color: black;
  font-weight: 500;
  font-size: 20px;
}

.selected-nav > a {
  text-decoration: underline;
  /* text-decoration-color: #ed1c24; */
  color: #ed1c24;
  font-weight: bold;
}
.option :hover {
  color: gray;
}

.house-icon {
  height: 22px;
  width: 22px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.mobile-menu {
  display: none;
}
@media (max-width: 648px) {
  /*Mobile View */
  .header-nav {
    padding: 0px 10px;
  }
  .nav-options {
    display: flex;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 90px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }
  .nav-options.active {
    background-color: white;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
  }

  .menu-icon {
    width: 45px;
    height: 45px;
  }

  .selected-nav > a {
    text-decoration: underline;
    /**text-decoration-color: #ed1c24;**/
    color: #ed1c24;
    font-weight: bold;
  }
  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vw;
    padding: 30px 0px;
  }
  .mobile-menu {
    display: block;
  }
}
